import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { shortMessage, shortName } from "../../utils";
import { emojiSkinTones, tippingAmountItems } from "../../data";
import adminAvatar from "../../assets/admin.svg";
import botAvatar from "../../assets/bot.svg";
import blankUserIcon from '../../assets/blank-user.svg';
import replyIcon from "../../assets/reply.svg";
import activeReplyIcon from "../../assets/active_reply.svg";
import heartIcon from "../../assets/heart.svg";
import filledHeartIcon from "../../assets/filledheart.svg";
import coinsIcon from "../../assets/coins.svg";
import lightGraySolSymbol from "../../assets/sol-symbol-light-gray.svg";
import closeIcon from "../../assets/close.svg";
import greenSolSymbol from "../../assets/sol-symbol-green.svg";
import useSound from "use-sound";

// @ts-ignore
import Fade from 'react-reveal/Fade';

interface IProps {
    messageId: string;
    username: string;
    publickey: string;
    userAvatar?: string;
    message: string;
    timestamp: number;
    isReply?: boolean;
    referenceUsername?: string;
    referenceMsg?: string;
    isFavourite?: boolean;
    staredCount?: number;
    userRole?: 'admin' | 'team' | 'bot' | 'user',
    isAdmin: boolean;
    isBot: boolean;
    referenceMsgId: string;
    isGift?: boolean;
    isGif?: boolean;
    userPubkey: string;
    userBalance: number;
    handleStarMessage: (messageId: string) => Promise<void>;
    handleSetReply: (msgId: string, username: string, message: string) => void;
    handleTipping: (receiverPubkey: string, tippingAmount: number, tippingMessage: string) => Promise<boolean>;
    handleScrollDown: (offsetY: number) => void;
    scrollMessagesToBottom: () => void; // Add this line
    triggerScrollToBottom: () => void; // Add this line
}

const MessageItem = (props: IProps) => {
    const {
        messageId,
        username,
        publickey,
        userAvatar,
        message,
        timestamp,
        isReply,
        referenceUsername,
        referenceMsg,
        isFavourite,
        staredCount,
        userRole,
        isAdmin,
        isBot,
        referenceMsgId,
        isGift,
        isGif,
        userPubkey,
        userBalance,
        handleStarMessage,
        handleSetReply,
        handleTipping,
        handleScrollDown,
        scrollMessagesToBottom,
        triggerScrollToBottom // Destructure triggerScrollToBottom
    } = props;
    const navigate = useNavigate();
    const [showTippingCard, setShowTippingCard] = useState<boolean>(false);
    const [tippingAmount, setTippingAmount] = useState<number>(0);
    const [tippingMessage, setTippingMessage] = useState<string>('');
    const [convertedMessage, setConvertedMessage] = useState<any>();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    const handleClick = async (event: React.MouseEvent, handler: () => Promise<void>) => {
        event.stopPropagation();
        await handler(); // Wait for the handler to complete
        console.log('Calling triggerScrollToBottom'); // Add this line
        triggerScrollToBottom();
    };

    let avatar = userAvatar;
    let color = "text-[#FFFFFF]";
    let borderColor = "border-none";

    if (!userAvatar) {
        avatar = blankUserIcon;
    }

    if (isAdmin) {
        avatar = adminAvatar;
        color = "text-[#FF007A]";
        borderColor = "border-[#FF007A]";
    } else {
        if (isBot) {
            avatar = botAvatar;
            color = "text-[#46FF78]";
            borderColor = "border-[#46FF78]";
        }
    }

    const beforeHandleTipping = async () => {
        if (!userPubkey) {
            toast.warn('Please connect wallet!');
            return;
        }

        if (userPubkey?.toLowerCase() == publickey?.toLowerCase()) {
            toast.warn('You are trying to give a tip to your wallet!');
            return;
        }

        if (tippingAmount <= 0) {
            toast.warn('Please select amount!');
            return;
        }

        if (tippingAmount * LAMPORTS_PER_SOL > userBalance) {
            toast.warn('Insufficient balance!');
            return;
        }

        if (!tippingMessage) {
            toast.warn('Please input a message!');
            return;
        }

        await handleTipping(publickey, tippingAmount, tippingMessage);
        setShowTippingCard(false);
        setTippingAmount(0);
        setTippingMessage('');
    }

    const toUni = function (str: string) {
        if (str.length < 4)
            return str.codePointAt(0)?.toString(16);
        return str.codePointAt(0)?.toString(16) + '-' + str.codePointAt(2)?.toString(16);
    };

    useEffect(() => {
        // const emojis = message.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu);
        // let newMessage = message;
        // if (emojis) {
        //     emojis.map((emoji, index) => {
        //         const currentUnified = toUni(emoji);

        //         if (emojiSkinTones.includes(currentUnified!)) {
        //             newMessage = newMessage.replaceAll(emoji, '')
        //         }

        //         if (currentUnified?.startsWith('1f') && !emojiSkinTones.includes(currentUnified)) {
        //             let unified = currentUnified;
        //             if (emojis[index + 1]) {
        //                 const nextUnified = toUni(emojis[index + 1]);
        //                 if (emojiSkinTones.includes(nextUnified!)) {
        //                     unified += '-' + nextUnified;
        //                 }
        //             }

        //             newMessage = newMessage.replaceAll(
        //                 emoji,
        //                 `<img src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${unified}.png" alt="grinning" class="chat-emoji" loading="eager" />`
        //             );
        //         }
        //     });

        //     setConvertedMessage(newMessage);
        // } else {
        //     setConvertedMessage(message);
        // }

        setConvertedMessage(message);
    }, [message]);

    return (
        <div className={`w-full ${staredCount && staredCount > 0 ? 'mb-[20px]' : 'mb-[10px]'} relative`}>
            <div className={`${isReply ? 'bg-[#21272C]' : 'bg-[#090C0E]'} rounded-[10px] relative`}>
                {
                    isReply && (
                        <div className="flex gap-1 text-[12px] pl-[13px] pt-[8px] pb-[10px]">
                            <div className="text-white">{`@${shortName(referenceUsername ?? "")}: `}</div>
                            <div className="text-[#808080]">{shortMessage(referenceMsg ?? "")}</div>
                        </div>
                    )
                }

                <div className={`flex ${!isGift ? 'bg-[#090C0E]' : 'bg-[linear-gradient(88deg,rgba(19,96,177,0.20)_0%,rgba(0,165,254,0.20)_100%)] border border-solid border-[#1360B1]'} rounded-[10px]`}>
                    <div className="p-[10px]">
                        <div className="w-[40px] h-[40px]">
                            <img
                                src={avatar}
                                alt='avatar'
                                className={`w-full h-full rounded-full border-[1px] border-solid ${borderColor}`}
                            />
                        </div>
                    </div>

                    <div className="relative grow pt-[8px] pr-[35px] pb-[18px] text-[14px]">
                        {
                            !isGift && (
                                <div className="absolute top-[8px] right-[6px] flex justify-center items-center gap-2">
{/*                                     <img
                                        src={coinsIcon}
                                        alt="coins-icon"
                                        className="cursor-pointer message-box-icon"
                                        onClick={() => {
                                            setShowTippingCard(!showTippingCard)
                                            setTimeout(() => {
                                                handleScrollDown(180);
                                            }, 50);
                                        }}
                                        onMouseEnter={() => playHover()}
                                    /> */}
                                    {
                                        referenceMsgId == messageId ? (
                                            <img
                                                src={activeReplyIcon}
                                                alt="reply-icon"
                                                className="cursor-pointer message-box-icon"
                                            />
                                        ) : (
                                            <img
                                                src={replyIcon}
                                                alt="reply-icon"
                                                className="cursor-pointer message-box-icon"
                                                onClick={async (event) => await handleClick(event, async () => {
                                                    await handleSetReply(messageId, username, message);
                                                })}
                                                onMouseEnter={() => playHover()}
                                            />
                                        )
                                    }
                                    <img
                                        src={isFavourite ? filledHeartIcon : heartIcon}
                                        alt="heart-icon"
                                        className="cursor-pointer message-box-icon"
                                        onClick={async (event) => await handleClick(event, async () => {
                                            await handleStarMessage(messageId);
                                        })}
                                        onMouseEnter={() => playHover()}
                                    />
                                </div>
                            )
                        }

                        <div
                            className={`font-medium cursor-pointer ${color}`}
                            onClick={() => navigate(`/user-stats/${publickey}`)}
                            onMouseEnter={() => playHover()}
                        >
                            {shortName(username)}
                        </div>
                        <div className="text-[#808080] w-full">
                            <div className="break-anywhere">
                                {
                                    !isGift ? (
                                        isGif ? (
                                            <div>
                                                <img src={message} alt="gif" className="h-[60px]" />
                                            </div>
                                        ) : (
                                            // <div dangerouslySetInnerHTML={{ __html: convertedMessage }}></div>
                                            convertedMessage
                                        )
                                    ) : (
                                        <div className="flex items-center gap-[3px]">
                                            <span className="text-white font-bold">Gifted <span className="text-[#46FF78]">{message.split(' ')[0]}</span></span>
                                            <img src={greenSolSymbol} alt="green-sol-symbol" className="w-[13.5px]" />
                                            <span>to {message.split(' ')[2] ? shortName(message.split(' ')[2], 10, 5) : shortName(message.split(' ')[1], 10, 5)}!</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        {
                            !isGift && (
                                <div className="absolute bg-[#21272C] rounded-[2px] text-[10px] text-[#808080] right-[6px] bottom-[3px] px-[6px]">
                                    {`${new Date(timestamp * 1000).toLocaleString()}`}
                                </div>
                            )
                        }
                    </div>
                </div>

                {
                    Number(staredCount) > 0 && (
                        <div className="absolute flex justify-center items-center gap-1 h-[18px] bg-[#21272C] rounded-[2px] px-1 left-[16px] bottom-[-9px]">
                            <div>{staredCount}</div>
                            <img
                                src={filledHeartIcon}
                                alt="heart-icon"
                                className="cursor-pointer"
                            />
                        </div>
                    )
                }
            </div>

            {
                showTippingCard && (
                    <Fade duration={1200} opposite>
                        <div className="w-full px-[5px]">
                            {/* Tipping Card */}
                            <div className="w-full bg-[linear-gradient(88deg,rgba(19,96,177,0.10)_0%,rgba(0,165,254,0.10)_100%)] rounded-[0px_0px_5px_5px] border-b border-b-[#1360B1] relative">
                                {/* Tipping Card Close button */}
                                <div className="absolute flex justify-end items-center w-full top-[12px] right-[12px]">
                                    <button onClick={() => setShowTippingCard(false)} onMouseEnter={() => playHover()} className="hover:scale-[1.15] transition-all">
                                        <img src={closeIcon} alt="close-icon" className="w-[11px]" />
                                    </button>
                                </div>

                                {/* Tipping Card Title */}
                                <div className="w-full p-[11px_17px]">
                                    <div className="flex items-center gap-[7px]">
                                        <span className="text-[14px] font-extrabold leading-normal">Gift Solana</span>
                                        <img src={lightGraySolSymbol} alt="gray-sol-symbol" className="w-[12px]" />
                                    </div>
                                </div>

                                {/* Tipping Card Body */}
                                <div className="w-full px-[27px] pb-[12px]">
                                    <div className="flex flex-col w-full">
                                        <input
                                            value={tippingMessage}
                                            placeholder="Say something..."
                                            className="w-full text-[10px] font-medium leading-normal p-[10px_11px] bg-[#090C0E] rounded-[10px] outline-none placeholder:text-[#808080]"
                                            onChange={(e) => setTippingMessage(e.target.value)}
                                        />

                                        <div className="w-full mt-[11px]">
                                            <span className="text-[12px] text-[#808080] font-inter font-semibold leading-normal">Gift Amount</span>
                                        </div>

                                        <div className="flex justify-between items-center w-full mt-[9px]">
                                            {
                                                tippingAmount <= 0 ? tippingAmountItems.map((tippingAmount, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            className="flex justify-center items-center w-[40px] h-[25px] text-[10px] font-bold leading-normal rounded-[10px] border border-solid border-[#1360B1] bg-[linear-gradient(88deg,rgba(19,96,177,0.30)_0%,rgba(0,165,254,0.30)_100%)] hover:scale-[1.15] transition-all"
                                                            onClick={() => setTippingAmount(tippingAmount)}
                                                            onMouseEnter={() => playHover()}
                                                        >
                                                            {tippingAmount}
                                                        </button>
                                                    )
                                                }) : (
                                                    <>
                                                        <span
                                                            className="flex justify-center items-center w-[40px] h-[25px] text-[10px] font-bold leading-normal rounded-[10px] border border-solid border-[#1360B1] bg-[linear-gradient(88deg,rgba(19,96,177,0.30)_0%,rgba(0,165,254,0.30)_100%)]"
                                                        >
                                                            {tippingAmount}
                                                        </span>

                                                        <button
                                                            className="flex justify-center items-center w-[100px] h-[25px] text-[10px] font-extrabold leading-normal rounded-[10px] border border-solid border-[#46FF78] bg-[linear-gradient(88deg,#037703_0%,#2AD46E_100%)] hover:scale-[1.15] transition-all"
                                                                onClick={beforeHandleTipping}
                                                                onMouseEnter={() => playHover()}
                                                        >
                                                            Confirm
                                                        </button>

                                                        <div className="flex justify-center items-center w-[40px] h-[25px]">
                                                            <button
                                                                className="flex justify-center items-center w-[16px] h-[16px] hover:scale-[1.15] transition-all"
                                                                    onClick={() => setTippingAmount(0)}
                                                                    onMouseEnter={() => playHover()}
                                                            >
                                                                <img src={replyIcon} alt="reply-icon" className="w-full h-full" />
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                )
            }
        </div>
    )
};

export default MessageItem;