import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  LedgerWalletAdapter
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import { Cluster, clusterApiUrl } from "@solana/web3.js";
import { ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import Home from "./components/Home";
import RpsGame from "./components/RpsGame";
import DiceGame from "./components/DiceGame";
import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import LiveChat from "./components/LiveChat";
import Coinflip from "./components/Coinflip";
import SolShuffle from "./components/SolShuffle";
import Solanaball from "./components/Solanaball";
import UserStats from "./components/UserStats";
import Leaderboard from "./components/Leaderboard";
import Quests from "./components/Quests";
import Rewards from "./components/Rewards";
import BannedUser from "./components/BannedUser";
import Admin from "./components/Admin";
import OldQuests from "./components/OldQuests";
import DiceGame2 from "./components/DiceGame2";
import CoinflipOnchain from "./components/CoinflipOnchain";
import Store from "./components/Store";
import Bot from "./components/Bot";
import Mint from "./components/Mint";

const network = process.env.REACT_APP_NETWORK || "devnet";

function App() {
  const endpoint = useMemo(() => clusterApiUrl(network as Cluster), [network]);
  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SlopeWalletAdapter(), new SolflareWalletAdapter(), new LedgerWalletAdapter()],
    [network]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const rpsSocket = io("/rps");
    const diceSocket = io("/dice");
    const dice2Socket = io("/dice2");
    const userSocket = io("/user");
    const lotterySocket = io("/lottery");
    const coinflipSocket = io("/coinflip");
    const messageSocket = io("/message");
    const shopSocket = io("/shop");

    dispatch({ type: "LOAD_RPS_SOCKET", payload: rpsSocket });
    dispatch({ type: "LOAD_DICE_SOCKET", payload: diceSocket });
    dispatch({ type: "LOAD_DICE2_SOCKET", payload: dice2Socket });
    dispatch({ type: "LOAD_USER_SOCKET", payload: userSocket });
    dispatch({ type: "LOAD_LOTTERY_SOCKET", payload: lotterySocket });
    dispatch({ type: "LOAD_COINFLIP_SOCKET", payload: coinflipSocket });
    dispatch({ type: "LOAD_MESSAGE_SOCKET", payload: messageSocket });
    dispatch({ type: "LOAD_SHOP_SOCKET", payload: shopSocket });
  }, []);

  const AppLayout = () => (
    <div className='overflow-x-hidden'>
      <Navbar />
      <main className="h-screen overflow-hidden pt-[59px] md:pt-[70px]">
        <div className="flex w-full h-full">
          <SideBar />
          <div className="grow overflow-y-auto no-scroll-bar">
            <Outlet />
          </div>
          <LiveChat />
        </div>
      </main>
    </div>
  );

  return (
    <div className="App">
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<Navigate to="/coinflip" />} />
                <Route path="/" element={<AppLayout />} >
                  <Route path="/" element={<Home />} />
                  <Route path="/rps" element={<Navigate to="/coinflip" replace />} />
                  <Route path="/dice" element={<Navigate to="/coinflip" replace />} />
                  <Route path="/dice2" element={<DiceGame2 />} />
                  <Route path="/coinflip" element={<Coinflip />} />
                  <Route path="/On-Chain" element={<CoinflipOnchain />} />
                  <Route path="/sol-shuffle" element={<SolShuffle />} />
                  <Route path="/solanaball" element={<Solanaball />} />
                  <Route path="/user-stats/:publicKey" element={<UserStats />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/quests" element={<Quests />} />
                  <Route path="/old-quests" element={<OldQuests />} />
                  <Route path="/rewards" element={<Rewards />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/bot" element={<Bot />} />
                  <Route path="/mint" element={<Mint />} />
                </Route>
                <Route path="/bannedUser" >
                  <Route path="/bannedUser" element={<BannedUser />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </WalletModalProvider>
          <ToastContainer autoClose={1500} />
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
