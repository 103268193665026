import { RoutesTypeEnum } from '../enums';
import coinsGrayIcon from '../assets/sidebar/coins-gray.svg';
import coinsWhiteIcon from '../assets/sidebar/coins-white.svg';
import diceGrayIcon from '../assets/sidebar/dice-gray.svg';
import diceWhiteIcon from '../assets/sidebar/dice-white.svg';
import rpsGrayIcon from '../assets/sidebar/rps-gray.svg';
import rpsWhiteIcon from '../assets/sidebar/rps-white.svg';
import cardsGrayIcon from '../assets/sidebar/cards-gray.svg';
import cardsWhiteIcon from '../assets/sidebar/cards-white.svg';
import ballGrayIcon from '../assets/sidebar/ball-gray.svg';
import ballWhiteIcon from '../assets/sidebar/ball-white.svg';
import shopIcon from '../assets/sidebar/shop.svg';
import giftIcon from '../assets/sidebar/gift.svg';
import journeyIcon from '../assets/sidebar/journey.svg';
import leaderboardIcon from '../assets/sidebar/leaderboard.svg';
import personGrayIcon from '../assets/sidebar/person-gray.svg';
import personWhiteIcon from '../assets/sidebar/person-white.svg';
import supportGrayIcon from '../assets/sidebar/support-gray.svg';
import supportWhiteIcon from '../assets/sidebar/support-white.svg';
import discordGrayIcon from '../assets/sidebar/discord-gray.svg';
import discordWhiteIcon from '../assets/sidebar/discord-white.svg';

export const routes = [
    // main
    {
        id: 1,
        title: "Coinflip",
        displayName: "Coinflip",
        font: "font-primary",
        to: "/coinflip",
        order: "order-1",
        icon: coinsGrayIcon,
        hoverIcon: coinsWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: true,
    },
    {
        id: 2,
        title: "Dice",
        displayName: "Dice",
        font: "font-primary",
        to: "/dice2",
        order: "order-2",
        icon: diceGrayIcon,
        hoverIcon: diceWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: true,
    },
    {
        id: 3,
        title: "Dice",
        displayName: "Dice",
        font: "font-primary",
        to: "/dice",
        order: "order-3",
        icon: diceGrayIcon,
        hoverIcon: diceWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: false,
    },
/*     {
        id: 4,
        title: "RPS",
        displayName: "RPS",
        font: "font-primary",
        to: "/rps",
        order: "order-4",
        icon: rpsGrayIcon,
        hoverIcon: rpsWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: true,
    }, */
    {
        id: 5,
        title: "Shuffle",
        displayName: "Shuffle",
        font: "font-primary",
        to: "/sol-shuffle",
        order: "order-5",
        icon: cardsGrayIcon,
        hoverIcon: cardsWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: true,
    },
    {
        id: 6,
        title: "Solanaball",
        displayName: "Solanaball",
        font: "font-primary",
        to: "/solanaball",
        order: "order-6",
        icon: ballGrayIcon,
        hoverIcon: ballWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        hoverBgColor: 'hover:bg-[#11161B]/50',
        type: RoutesTypeEnum.Main,
        isAuth: false,
        visible: true,
    },

    // feature
    {
        id: 7,
        title: "Store",
        displayName: "Store",
        font: "font-primary",
        to: "/store",
        order: "order-7",
        icon: shopIcon,
        hoverIcon: shopIcon,
        activeBgColor: 'bg-[#17222E]',
        hoverBgColor: 'hover:bg-[#17222E]',
        type: RoutesTypeEnum.Feature,
        isAuth: false,
        visible: true,
    },
    {
        id: 8,
        title: "Rewards",
        displayName: "Rewards",
        font: "font-primary",
        to: "/rewards",
        order: "order-8",
        icon: giftIcon,
        hoverIcon: giftIcon,
        activeBgColor: 'bg-[#142E25]',
        hoverBgColor: 'hover:bg-[#142E25]',
        type: RoutesTypeEnum.Feature,
        isAuth: true,
        visible: true,
    },
    {
        id: 9,
        title: "Quests",
        displayName: "Put your Wolf on a Journey!",
        font: "font-primary",
        to: "/quests",
        order: "order-9",
        icon: journeyIcon,
        hoverIcon: journeyIcon,
        activeBgColor: 'bg-[#3A3220]',
        hoverBgColor: 'hover:bg-[#3A3220]',
        type: RoutesTypeEnum.Feature,
        isAuth: false,
        visible: true,
    },
    {
        id: 10,
        title: "Wager Race",
        displayName: "Leaderboard",
        font: "font-primary",
        to: "/leaderboard",
        order: "order-10",
        icon: leaderboardIcon,
        hoverIcon: leaderboardIcon,
        activeBgColor: 'bg-[#3C3A29]',
        hoverBgColor: 'hover:bg-[#3C3A29]',
        type: RoutesTypeEnum.Feature,
        isAuth: false,
        visible: true,
    },

    // other
    {
        id: 11,
        title: "Profile",
        displayName: "Profile",
        font: "font-primary",
        to: "/user-stats",
        order: "order-11",
        icon: personGrayIcon,
        hoverIcon: personWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        type: RoutesTypeEnum.Other,
        isAuth: false,
        visible: true,
    },
    {
        id: 12,
        title: "Support",
        displayName: "Support",
        font: "font-primary",
        to: "https://docs.biteclub.io/home/start-here",
        order: "order-12",
        icon: supportGrayIcon,
        hoverIcon: supportWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        type: RoutesTypeEnum.Other,
        isAuth: false,
        visible: true,
    },
    {
        id: 13,
        title: "Discord",
        displayName: "Discord",
        font: "font-primary",
        to: "https://discord.gg/pZZWHsRKaJ",
        order: "order-13",
        icon: discordGrayIcon,
        hoverIcon: discordWhiteIcon,
        activeBgColor: 'bg-[#11161B]',
        type: RoutesTypeEnum.Other,
        isAuth: false,
        visible: true,
    },
];